@import url('https://fonts.googleapis.com/css2?family=Exo:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  letter-spacing: 1px;
  box-sizing: border-box;
}

body {
  max-width: 100vw;
  min-width: 100vw;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-size: 26px;
  line-height: 34px;
  font-weight: 600;
}

h1 span{
  color: #368FE2;
}

p, li, a, button, input, textarea, label {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}

li{
  list-style: none;
}

button{
  cursor: pointer;
}

input, button, textarea{
  color: inherit;
  outline: none;
  border: none;
  background-color: transparent;
}

textarea::placeholder, input::placeholder{
  color: inherit;
  opacity: .75;
}

.ref{
  position: absolute;
  top: 0px;
}

@media (max-width: 1100px){
  h1 {
    font-size: 22px;
    line-height: 30px;
  }
  
  p, li, a, button, input, textarea, label {
    font-size: 14px;
    line-height: 22px;
  }

  .ref{
    top: -10px;
  }
}

@media (max-width: 769px){
  h1 {
    font-size: 18px;
    line-height: 26px;
  }
  
  p, li, a, button, input, textarea, label {
    font-size: 12px;
    line-height: 20px;
  }

  .ref{
    top: -15px;
  }
}